:root {
  --transition: all 400ms ease;
  --light-blue: #00deff;
  --dark-blue: #182530;
  --gray: #626a71;
  --light-gray-info: #9fa9b1;
  --light-gray-window-frame: #a6b0b9;
  --bright-red: #f92056;
  --border-red-blue-gradient: linear-gradient( 45deg, var(--bright-red) 38%, var(--light-blue) 62% ) 1 / 1 / 0 stretch;
  --bg-red-blue-gradient: linear-gradient( 45deg, var(--bright-red) 38%, var(--light-blue) 62% );
  --events-dark-blue: #1a2331;
  --red-gradient: linear-gradient(var(--bright-red), var(--bright-red) );
  --blue-gradient: linear-gradient(var(--light-blue), var(--light-blue) );
  --input-border-dark-blue: #33414c;
  --window-transition: .5s ease-in-out;
}

* {
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6rem;
}

html {
  scroll-behavior: smooth;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
}

.btn-text {
  text-transform: uppercase;
  font-size: .769rem;
  font-weight: 700;
  line-height: 1rem;
}

.extra-large-text {
  letter-spacing: -.1rem;
  text-transform: uppercase;
  word-wrap: break-word;
  font-size: 6.15rem;
  line-height: 8rem;
}

.small-gray-text {
  color: var(--light-gray-info);
  font-size: .813rem;
  line-height: 1.382rem;
}

.small-bold-text {
  font-size: .846rem;
  font-weight: 700;
}

.small-text-red-bg {
  background-color: var(--bright-red);
  padding: .15rem .8rem .3rem;
  font-size: .812rem;
  line-height: 1.3rem;
}

.small-text {
  font-size: .866rem;
  line-height: 1.4rem;
}

.small-bold-text-high-line-height {
  text-transform: uppercase;
  font-size: .866rem;
  font-weight: 700;
  line-height: 1.4rem;
}

.extra-small-text {
  font-size: .67rem;
  line-height: .933rem;
}

.large-text {
  font-size: 2.077rem;
  line-height: 2.7rem;
}

.large-bold-text {
  font-size: 2.077rem;
  font-weight: 700;
  line-height: 2.7rem;
}

.normal-bold-text {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.96rem;
}

.normal-text {
  font-size: 1.6rem;
  line-height: 1-96rem;
}

.logo-pic, .dtmp-logo {
  height: 6.25rem;
}

.icon-frame {
  width: 3rem;
  height: 3rem;
  background-color: var(--gray);
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon {
  width: 1.35rem;
  height: 1.35rem;
  fill: #fff;
  display: inline-block;
}

.icon-frame:hover {
  background-color: var(--bright-red);
  cursor: pointer;
  transition: var(--transition);
}

.gradient-text {
  background: var(--bg-red-blue-gradient);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-style: italic;
  font-weight: 700;
}

.section-title-container {
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
  display: flex;
}

.section-title {
  margin: 6.846rem 0 6rem;
  padding: 0 1rem;
}

.page-container {
  background-color: #000;
}

.nav-bar-section {
  background-color: var(--dark-blue);
  width: 100%;
  z-index: 1;
  justify-content: center;
  padding: .75rem 0;
  display: flex;
  position: fixed;
  top: 0;
}

.nav-bar-container {
  width: 73.125rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nav-btns {
  flex-flow: wrap;
  display: flex;
}

.nav-btn-frame {
  padding: 1.5rem;
}

.nav-btn:hover {
  letter-spacing: .2em;
  transition: var(--transition);
}

.nav-btn-frame:has(.nav-btn:active) {
  background-color: var(--gray);
  transition: var(--transition);
}

.nav-btns-container {
  padding-right: 1.5rem;
}

.nav-bar-logo-container {
  padding: 0 1.5rem;
}

.social-links {
  flex-direction: row;
  gap: .9rem;
  padding-right: 1.5rem;
  display: flex;
}

.nav-bar {
  flex-direction: column;
  align-items: end;
  gap: .9rem;
  display: flex;
}

.page-content-container {
  width: 81.5rem;
  border-image: var(--border-red-blue-gradient);
  border-width: 4px;
  border-left-style: solid;
  border-right-style: solid;
  overflow: hidden;
}

.page-content-section {
  justify-content: center;
  align-items: center;
  display: flex;
}

.hero-pic {
  width: 81rem;
}

.hero {
  justify-content: center;
  padding-top: 10rem;
  display: flex;
}

.hero-pic-container {
  height: 26.44rem;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.events-section .event-date {
  align-items: center;
  gap: .5rem;
  margin-bottom: 1.625rem;
  padding: .125rem 0;
  display: flex;
}

.events-section .calendar-icon {
  fill: var(--bright-red);
  width: 1.15rem;
  height: 1.15rem;
}

.events-section .date {
  text-transform: uppercase;
  padding-top: .125rem;
}

.events-section .event-name {
  margin: 1.5rem 0 1.22rem;
}

.poster {
  height: 23.125rem;
}

.event-container {
  width: fit-content;
  background-color: var(--events-dark-blue);
  flex-flow: wrap;
  align-items: center;
  padding: 1.875rem;
  display: flex;
}

.events-section .event-poster-box {
  width: 16.3rem;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.right-side-event-card {
  width: 24.58rem;
  flex-direction: column;
  margin: 1.875rem 0 1.875rem 2.54rem;
  display: flex;
}

.event-list {
  flex-direction: column;
  align-items: center;
  gap: 2.308rem;
  display: flex;
}

.event-numb {
  padding-right: .4rem;
  font-size: 2.77rem;
}

.dj-card {
  width: fit-content;
  flex-direction: column;
  align-items: center;
  padding: 2.77rem;
  display: flex;
  position: relative;
}

.dj-card.card-frame {
  background-image: var(--blue-gradient), var(--blue-gradient), var(--red-gradient), var(--red-gradient);
  background-position: 100% 100%, 100% 100%, 0 0, 0 0;
  background-repeat: no-repeat;
  background-size: 30% .25rem, .25rem 30%, .25rem 30%, 30% .25rem;
}

.dj-name {
  margin: 1.551rem 0 .4rem;
}

.dj-info {
  max-width: 30.7rem;
  margin-top: 2.77rem;
}

.dj-list {
  flex-direction: column;
  align-items: center;
  gap: 4.875rem;
  display: flex;
}

.dj-profile-pic-box {
  width: 14.8rem;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.dj-profile-pic {
  height: 18.5rem;
}

.purchase-details {
  max-width: 32.188rem;
  flex-direction: column;
  gap: 1.22rem;
  display: flex;
}

.field {
  border: none;
  border-bottom: 1px solid var(--input-border-dark-blue);
  transition: var(--transition);
  background-color: rgba(0, 0, 0, 0);
  padding-bottom: .812rem;
}

.field::-ms-input-placeholder {
  color: #fff;
  text-transform: uppercase;
  font-size: .866rem;
  font-weight: 700;
  line-height: 1.4rem;
}

.field::placeholder {
  color: #fff;
  text-transform: uppercase;
  font-size: .866rem;
  font-weight: 700;
  line-height: 1.4rem;
}

.client-details .field:focus {
  border-bottom-color: var(--light-blue);
  outline: none;
}

.tickets-section {
  flex-direction: column;
  align-items: center;
  margin-bottom: 7.312rem;
  display: flex;
}

.tickets-section .event-details {
  width: fit-content;
  flex-direction: column;
  gap: 1.22rem;
  display: flex;
}

.client-details {
  flex-direction: column;
  gap: 1.22rem;
  display: flex;
}

.pay-btn {
  letter-spacing: .5rem;
  border: 3px solid;
  border-color: var(--light-blue);
  width: 100%;
  background: none;
  padding: 1.4rem 0;
}

.pay-btn:hover {
  transition: var(--transition);
  border-color: var(--bright-red);
  cursor: pointer;
}

.payment-methods-banner {
  height: 2.5rem;
}

.payment-methods-container {
  flex-direction: column;
  gap: .82rem;
  display: flex;
}

.more-info-payment-methods {
  margin-bottom: .4rem;
}

.payment-methods-container .dtmp-link {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  line-height: inherit;
  text-decoration: underline;
}

.payment-methods-info {
  flex-direction: column;
  gap: .2rem;
  display: flex;
}

.footer-top {
  flex-flow: wrap;
  justify-content: center;
  gap: 2rem;
  display: flex;
}

.footer {
  background-color: var(--dark-blue);
  justify-content: center;
  align-items: end;
  padding: .625rem 0;
  display: flex;
}

.left-side-footer {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.right-side-footer {
  align-items: center;
  display: flex;
}

.contacts-container {
  flex-direction: column;
  gap: .4rem;
  display: flex;
}

.footer-social-links {
  align-items: center;
  gap: .9rem;
  display: flex;
}

.footer-bottom {
  padding-left: 1.5rem;
}

.footer-container {
  width: 73.125rem;
  flex-direction: column;
  gap: .9rem;
  display: flex;
}

.online-payments-logo-container {
  flex-direction: row;
  gap: .9rem;
  display: flex;
}

.online-payments-logo {
  height: 1.5rem;
}

.anpc-logo {
  height: 1.6rem;
  background-color: #fff;
  padding: .1rem;
}

.anpc-logo-frame {
  height: 1.6rem;
}

.copyright {
  background: var(--bg-red-blue-gradient);
}

.copyright-container {
  flex-direction: column;
  align-items: center;
  padding: .3rem;
  display: flex;
}

.author-social-link {
  font-size: .75rem;
  font-weight: 600;
  line-height: 1.088rem;
}

.payment-welcome-window {
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: var(--window-transition);
  background-color: rgba(0, 0, 0, .6);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.payment-welcome-message-content {
  flex-direction: column;
  align-items: center;
  gap: 2.3rem;
  padding-top: 4rem;
  display: flex;
}

.payment-welcome-window-content {
  width: 47.125rem;
  border: 2px solid var(--light-gray-window-frame);
  transition: var(--window-transition);
  background-color: #000;
  border-radius: 10px;
  padding: 2.8rem;
  position: relative;
}

.close-btn-frame {
  height: 1.6rem;
  width: 1.6rem;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #fff;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.close-btn {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  position: absolute;
  top: 2.8rem;
  right: 2.8rem;
}

.close-btn-frame:hover .close-btn-icon {
  fill: var(--bright-red);
}

.close-btn-frame:hover {
  cursor: pointer;
  border-color: var(--bright-red);
}

.hidden-window .payment-welcome-window-content {
  visibility: hidden;
  transform: scale(.9);
}

.hidden-window.payment-welcome-window {
  opacity: .9;
  visibility: hidden;
}

.payment-platform-btn {
  height: 6.75rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 8px;
  padding: 4px;
}

@media screen and (max-width: 769px) {
  * {
    font-size: .8rem;
    line-height: 1.28rem;
  }

  .large-text, .large-bold-text, .extra-large-text {
    text-align: center;
  }

  .section-title {
    width: 27rem;
    text-align: center;
  }

  .sections {
    padding: 2rem;
  }

  .event-container {
    max-width: 26rem;
    height: 53.94rem;
    align-content: center;
    justify-content: center;
  }

  .event-list {
    flex-flow: wrap;
    justify-content: center;
    gap: 1.5rem;
  }

  .event {
    height: 100%;
  }

  .right-side-event-card {
    width: 100%;
    margin-top: 2.54rem;
    margin-bottom: 0;
    margin-left: 0;
  }

  .event-name-box {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .event-numb {
    padding: .4rem;
  }

  .dj-list {
    flex-flow: wrap;
    justify-content: center;
    gap: 1.5rem;
  }

  .dj-card {
    max-width: 26rem;
    height: 56.29rem;
    justify-content: center;
    display: flex;
  }

  .purchase-details {
    max-width: 35rem;
  }
}

@media screen and (max-width: 426px) {
  * {
    font-size: .7rem;
    line-height: 1.12rem;
  }

  .section-title {
    width: 29rem;
  }
}

/*# sourceMappingURL=index.dd8f5617.css.map */
