/* default styling */

:root {
  --transition: all 400ms ease;
  --light-blue: #00deff;
  --dark-blue: #182530;
  --gray: #626a71;
  --light-gray-info: #9fa9b1;
  --light-gray-window-frame: #a6b0b9;
  --bright-red: #f92056;
  --border-red-blue-gradient: linear-gradient(
      45deg,
      var(--bright-red) 38%,
      var(--light-blue) 62%
    )
    1/1/0 stretch;
  --bg-red-blue-gradient: linear-gradient(
    45deg,
    var(--bright-red) 38%,
    var(--light-blue) 62%
  );
  --events-dark-blue: #1a2331;
  --red-gradient: linear-gradient(var(--bright-red), var(--bright-red));
  --blue-gradient: linear-gradient(var(--light-blue), var(--light-blue));
  --input-border-dark-blue: #33414c;
  --window-transition: 0.5s ease-in-out;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: white;
}

html {
  scroll-behavior: smooth;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
}

/*text syling */

.btn-text {
  font-size: 0.769rem;
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.extra-large-text {
  font-size: 6.15rem;
  /* border-bottom-width: 0.25rem; */
  /* border-bottom-style: solid;
  border-image: var(--red-blue-gradient-color); */
  /* padding: 1.4rem 2.2rem; */
  /* margin: 6.846rem 0 6rem 0; */
  line-height: 8rem;
  letter-spacing: -0.1rem;
  text-transform: uppercase;
  /* padding: 0 1.6rem; */
  /* overflow: visible; */
  word-wrap: break-word;
}

.small-gray-text {
  line-height: 1.382rem;
  color: var(--light-gray-info);
  /* width: 20.1rem; */
  font-size: 0.813rem;
}

.small-bold-text {
  font-size: 0.846rem;
  font-weight: 700;
  /* padding: 0.1rem 0.6rem; */
  /* margin: 0.9rem; */
  /* display: block; */
  /* background-color: var(--bright-red); */
}

.small-text-red-bg {
  background-color: var(--bright-red);
  padding: 0.15rem 0.8rem 0.3rem;
  font-size: 0.812rem;
  line-height: 1.3rem;
}

.small-text {
  /* height: 1.37rem; */
  /* color: white; */
  /* border-radius: 10; */
  line-height: 1.4rem;
  font-size: 0.866rem;
  /* font-weight: 500; */
}

.small-bold-text-high-line-height {
  font-size: 0.866rem;
  font-weight: 700;
  line-height: 1.4rem;
  text-transform: uppercase;
}

.extra-small-text {
  line-height: 0.933rem;
  font-size: 0.67rem;
}

.large-text {
  font-size: 2.077rem;
  line-height: 2.7rem;
}

.large-bold-text {
  font-size: 2.077rem;
  line-height: 2.7rem;
  font-weight: 700;
}

.normal-bold-text {
  font-size: 1.6rem;
  line-height: 1.96rem;
  font-weight: 700;
}

.normal-text {
  font-size: 1.6rem;
  line-height: 1-96rem;
}

/*image size */

.logo-pic {
  /* width: 100%; */
  height: 6.25rem;
}

.dtmp-logo {
  height: 6.25rem;
}

/*social links styling */

.icon-frame {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray);
}

.icon {
  width: 1.35rem;
  height: 1.35rem;
  display: inline-block;
  fill: white;
  /* margin-top: 0.4rem; */
}

.icon-frame:hover {
  background-color: var(--bright-red);
  cursor: pointer;
  transition: var(--transition);
}

/* text background styling */

.gradient-text {
  background: var(--bg-red-blue-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: italic;
  font-weight: 700;
}

/* section title styling */

.section-title-container {
  padding-top: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 14rem; */
}

.section-title {
  margin: 6.846rem 0 6rem 0;
  padding: 0 1rem;
  /* word-wrap: break-word; */
}

/* entire page */

.page-container {
  background-color: black;
  /* height: 100vh; */
}

/* navigation bar section*/

.nav-bar-section {
  background-color: var(--dark-blue);
  display: flex;
  justify-content: center;
  /* height: 10rem; */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 0.75rem 0;
}

.nav-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 10rem; */
  width: 73.125rem;
  /* flex-wrap: wrap; */
}

.nav-btns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.nav-btn-frame {
  padding: 1.5rem;
  /* margin: 1.72rem 0 1.8275rem; */
  /* align-content: end; */
}

/* .nav-btn {
  color: white;
} */

.nav-btn:hover {
  /* color: #a6b0b9; */
  letter-spacing: 0.2em;
  transition: var(--transition);
}

.nav-btn-frame:has(.nav-btn:active) {
  background-color: var(--gray);
  transition: var(--transition);
}

.nav-btns-container {
  padding-right: 1.5rem;
}

.nav-bar-logo-container {
  padding: 0 1.5rem;
  /* height: 6.25rem; */
}

.social-links {
  display: flex;
  flex-direction: row;
  gap: 0.9rem;
  padding-right: 1.5rem;
}

.nav-bar {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 0.9rem;
}

/* .social-icon:hover {
  transform: scale(1.15);
  transition: var(--transition);
} */

/* page content section */

.page-content-container {
  width: 81.5rem;
  /* padding: 0 4rem; */
  /* border-width: 0.5rem;
  border-left-style: solid;
  border-right-style: solid;
  border-image: var(--red-blue-gradient-color); */
  border-width: 4px;
  border-left-style: solid;
  border-right-style: solid;
  border-image: var(--border-red-blue-gradient);
  overflow: hidden;
}

.page-content-section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 10rem; */
  /* position: relative; */
}

/* hero section */

.hero-pic {
  width: 81rem;
  /* overflow: hidden; */
}

.hero {
  display: flex;
  justify-content: center;
  padding-top: 10rem;
  /* border: none; */
  /* position: absolute; */
}

.hero-pic-container {
  /* width: 73.125rem; */
  height: 26.44rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-width: 0.25rem; */
}

/* events section */

.events-section .event-date {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 1.625rem;
  padding: 0.125rem 0;
  /* padding-left: 3.558rem; */
  gap: 0.5rem;
}

.events-section .calendar-icon {
  fill: var(--bright-red);
  width: 1.15rem;
  height: 1.15rem;
}

.events-section .date {
  /* font-size: 0.846rem;
  font-weight: 700; */
  text-transform: uppercase;
  padding-top: 0.125rem;
}

.events-section .event-name {
  /* font-size: 2.077rem; */
  /* font-weight: 400; */
  /* border-left-width: 0.25rem;
  border-left-style: solid;
  border-image: var(--red-blue-gradient-color);
  padding: 1.4rem 0;
  padding-left: 3.308rem; */
  margin: 1.5rem 0 1.22rem;
  /* line-height: 2.7rem; */
}

/* .event-info {
  padding-left: 3.558rem;
} */

.poster {
  height: 23.125rem;
}

.event-container {
  /* border-width: 0.25rem; */
  width: fit-content;
  /* border-style: solid;
  border-image: var(--red-blue-gradient-color); */
  /* border-radius: 5rem; */
  background-color: var(--events-dark-blue);
  display: flex;
  flex-direction: row;
  /* align-items: baseline; */
  /* justify-content: baseline; */
  /* gap: 1.875rem; */
  /* border-style: solid;
  border-image: var(--red-blue-gradient-color); */
  padding: 1.875rem;
  align-items: center;
  flex-wrap: wrap;
}

.events-section .event-poster-box {
  width: 16.3rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.right-side-event-card {
  margin: 1.875rem 0 1.875rem 2.54rem;
  display: flex;
  flex-direction: column;
  /* justify-content: baseline; */
  width: 24.58rem;
}

.event-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.308rem;
}

.event-numb {
  font-size: 2.77rem;
  padding-right: 0.4rem;
}

/* .events-section {
  position: relative;
  z-index: 1;
} */

/* djs section */

.dj-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* border-top-style: double;
  border-image: var(--red-blue-color); */
  padding: 2.77rem;
  width: fit-content;
  /* margin: 0 20rem; */
}

.dj-card.card-frame {
  background-image: var(--blue-gradient), var(--blue-gradient),
    var(--red-gradient), var(--red-gradient);
  background-repeat: no-repeat;
  background-size: 30% 0.25rem, 0.25rem 30%, 0.25rem 30%, 30% 0.25rem;
  background-position: right bottom, right bottom, left top, left top;
  /* margin: 20px; */
  /* padding: 0.25rem; */
}

.dj-name {
  margin: 1.551rem 0 0.4rem;
  /* font-size: 2.077rem;
  line-height: 2.7rem;
  font-weight: 700; */
}

/* .dj-style {
  color: var(--bright-red);
} */

.dj-info {
  margin-top: 2.77rem;
  max-width: 30.7rem;
}

.dj-list {
  display: flex;
  flex-direction: column;
  gap: 4.875rem;
  align-items: center;
  /* padding: 0 20rem; */
}

.dj-profile-pic-box {
  width: 14.8rem;
  /* height: 18.5rem; */
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.dj-profile-pic {
  height: 18.5rem;
}

/* tickets */

.purchase-details {
  display: flex;
  flex-direction: column;
  gap: 1.22rem;
  max-width: 32.188rem;
}

/* .section-title-container {
  display: flex;
  flex-direction: column;
} */

.field {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--input-border-dark-blue);
  padding-bottom: 0.812rem;

  transition: var(--transition);
}

.field::placeholder {
  color: white;
  font-size: 0.866rem;
  font-weight: 700;
  line-height: 1.4rem;
  text-transform: uppercase;
}

.client-details .field:focus {
  outline: none;
  /* border-radius: 10; */
  /* transition: var(--transition); */
  border-bottom-color: var(--light-blue);
}

/* .event-details .small-text {
  border: none;
} */

.tickets-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7.312rem;
}

.tickets-section .event-details {
  display: flex;
  flex-direction: column;
  gap: 1.22rem;
  width: fit-content;
}

.client-details {
  display: flex;
  flex-direction: column;
  gap: 1.22rem;
}

/* .pay-btn-container {
  width: fit-content;
} */

.pay-btn {
  /* display: block; */
  background: transparent;
  border: none;
  /* color: white; */
  /* font-size: 0.769rem;
  font-weight: 700;
  text-transform: uppercase; */
  letter-spacing: 0.5rem;
  padding: 1.4rem 0;
  border-width: 3px;
  border-style: solid;
  /* border-image: var(--red-blue-gradient); */
  border-color: var(--light-blue);
  /* width: 32.188rem; */
  width: 100%;
}

.pay-btn:hover {
  transition: var(--transition);
  border-color: var(--bright-red);
  cursor: pointer;
}

/* pay-logos */

.payment-methods-banner {
  height: 2.5rem;
}

/* .payment-methods-banner-container {
  margin-top: 6.846rem;
  display: flex;
  gap: 0.9rem;
  margin-top: 0.6rem;
} */

.payment-methods-container {
  display: flex;
  flex-direction: column;
  gap: 0.82rem;
}

.more-info-payment-methods {
  margin-bottom: 0.4rem;
}

.payment-methods-container .dtmp-link {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: underline;
  line-height: inherit;
}

.payment-methods-info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

/* .payment-methods-banner-container {
  display: flex;
  justify-content: center;
} */

/* footer */

.footer-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: end;
  background-color: var(--dark-blue);
  /* height: 10rem; */
  padding: 0.625rem 0;
}

/* .footer-top {
  justify-self: center;
  display: flex;
  align-items: center;
  width: 76.7rem;
} */

.left-side-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 0.9rem; */
}

.right-side-footer {
  display: flex;
  align-items: center;
}

.contacts-container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  /* justify-content: center; */
}

.footer-social-links {
  display: flex;
  gap: 0.9rem;
  align-items: center;
}

/* .footer-logo-container {
  height: 6.25rem;
} */

.footer-bottom {
  /* display: flex;
  justify-content: baseline;
  width: 76.7rem; */
  padding-left: 1.5rem;
}

.footer-container {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  width: 73.125rem;
}

.online-payments-logo-container {
  display: flex;
  flex-direction: row;
  gap: 0.9rem;
}

.online-payments-logo {
  height: 1.5rem;
}

.anpc-logo {
  background-color: white;
  padding: 0.1rem;
  height: 1.6rem;
}

.anpc-logo-frame {
  height: 1.6rem;
}

/* copyright section */

.copyright {
  background: var(--bg-red-blue-gradient);
}

.copyright-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.3rem;
}

/* .copyright-section {
  line-height: 0.866rem;
} */

/* .copyright-info {
  font-size: 0.67rem;
  line-height: normal;
} */

.author-social-link {
  font-size: 0.75rem;
  line-height: 1.088rem;
  font-weight: 600;
}

/* payment-welcome-window */

.payment-welcome-window {
  /* background-color: black; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  /* display: none; */
  transition: var(--window-transition);
  /* opacity: 0.8; */
}

/* .qr-code-pic {
  height: 10rem;
} */

.payment-welcome-message-content {
  display: flex;
  flex-direction: column;
  gap: 2.3rem;
  /* justify-content: center; */
  align-items: center;
  padding-top: 4rem;
}

.payment-welcome-window-content {
  width: 47.125rem;
  padding: 2.8rem;
  background-color: black;
  border: 2px solid var(--light-gray-window-frame);
  border-radius: 10px;
  transition: var(--window-transition);
  position: relative;
}

.close-btn-frame {
  height: 1.6rem;
  width: 1.6rem;
  /* background-color: var(--gray); */
  border: 2px solid white;
  background-color: transparent;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  border: none;
  background-color: transparent;
  /* float: right; */
  position: absolute;
  top: 2.8rem;
  right: 2.8rem;
}

.close-btn-frame:hover .close-btn-icon {
  fill: var(--bright-red);
}

.close-btn-frame:hover {
  cursor: pointer;
}

.close-btn-frame:hover {
  border-color: var(--bright-red);
}

.hidden-window .payment-welcome-window-content {
  transform: scale(0.9);
  visibility: hidden;
}

.hidden-window.payment-welcome-window {
  opacity: 0.9;
  visibility: hidden;
  /* pointer-events: auto; */
}

.payment-platform-btn {
  /* border: 2px solid var(--light-blue);
  border-radius: 8px; */
  background-color: transparent;
  padding: 4px;
  height: 6.75rem;
  border: none;
  border-radius: 8px;
}

/* .payment-platform .logo-pic {
  height: 5rem;
} */

/* .payment-platform-btn:hover {
  background-color: var(--light-blue);
} */

/* tablet version */

@media screen and (max-width: 769px) {
  * {
    font-size: 0.8rem;
    line-height: 1.28rem;
  }

  .large-text,
  .large-bold-text,
  .extra-large-text {
    text-align: center;
  }

  .section-title {
    width: 27rem;
    text-align: center;
  }

  .sections {
    padding: 2rem;
  }

  .event-container {
    justify-content: center;
    max-width: 26rem;
    height: 53.94rem;
    align-content: center;
    /* gap: 0; */
  }

  .event-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* height: 100%; */
    gap: 1.5rem;
  }

  .event {
    height: 100%;
  }

  .right-side-event-card {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 2.54rem;
    width: 100%;
  }

  .event-name-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .event-numb {
    padding: 0.4rem;
  }

  /* .dj {
    display: flex;
    justify-content: center;
  } */

  .dj-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
  }

  .dj-card {
    max-width: 26rem;
    height: 56.29rem;
    display: flex;
    justify-content: center;
  }

  .purchase-details {
    max-width: 35rem;
  }

  /* .payment-methods-banner {
    height: 2rem;
  } */
  /* .dj-info {
    max-width: 100%;
  } */
  /* .event-name-box {
    width: min-content;
  } */
}

/* mobile version */

@media screen and (max-width: 426px) {
  /* .nav-bar-logo-container {
    scale: 0.8;
  }

  .nav-btns-container {
    scale: 0.8;
  }

  .social-links {
    scale: 0.8;
  } */

  * {
    font-size: 0.7rem;
    line-height: 1.12rem;
  }

  .section-title {
    width: 29rem;
  }

  /* .event-container {
    width: 100%;
  } */

  /* .right-side-event-card {
    width: 100%;
  } */

  /* .payment-methods-banner {
    height: 1.7rem;
  } */
  /* .nav-bar-section {
    height: min-content;
  }
  .nav-bar-container {
    scale: 0.8;
  } */
}
